import * as React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/css";
import logo from "../assets/icon.png";
import { Layout } from "../components";

// styles
const notFoundPage = css`
  color: #232129;
  padding: 96px;
  font-family: -apple-system, Roboto, sans-serif, serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 40px;
`;

const headingStyles = css`
  margin-top: 0;
  max-width: 320;
`;

const paragraphStyles = css`
  line-height: 1.8rem;
  font-size: 20px;
`;

const AccessButtonStyle = css`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex: 1;

  & > .navDotter {
    position: relative;
    float: left;
    display: flex;
    padding: 12px 20px;
    text-decoration: inherit;
    color: inherit;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: white;
    background: linear-gradient(to right, #0b8d41, #5ccc87);
    border-radius: 40px;
    text-shadow: 1px 1px 1px #666;
    transition: 0.3s all;
    overflow: hidden;

    & > .glassyeffect {
      position: absolute;
      z-index: 0;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        110deg,
        rgba(0, 0, 0, 0) 14%,
        rgba(255, 255, 255, 0.1) 26%,
        rgba(255, 255, 255, 0.1) 44%,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0) 68%,
        rgba(255, 255, 255, 0.1) 78%,
        rgba(255, 255, 255, 0.1) 79%,
        rgba(0, 0, 0, 0) 100%
      );
      transition: 0.3s all;
    }

    & > .text {
      z-index: 2;
      padding-right: 8px;
    }

    & > .rocket {
      margin-left: 5px;
    }

    &:hover {
      letter-spacing: 0.2px;
      text-shadow: 1px 1px 1px #666;
      transition: 0.3s all;

      & > .rocket {
        animation: 2s infinite 0s flying;
      }

      & > .glassyeffect {
        left: 0;
        transition: 0.3s all;
      }
    }
  }

  @keyframes flying {
    0% {
      transform: translate(0, 0);
    }
    2% {
      transform: translate(2px, 2px);
    }
    4% {
      transform: translate(0, 0);
    }
    6% {
      transform: translate(2px, 2px);
    }
    8% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(2px, 2px);
    }
    12% {
      transform: translate(0, 0);
    }
    14% {
      transform: translate(2px, 2px);
    }
    16% {
      transform: translate(0, 0);
    }
    18% {
      transform: translate(2px, 2px);
    }

    50% {
      transform: translate(50px, -50px);
    }
    51% {
      transform: translate(50px, 50px);
    }
    52% {
      transform: translate(-50px, 50px);
    }

    100% {
      transform: translate(0, 0);
    }
  }
`;

// markup
const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    return (
      <div className={notFoundPage as any}>
        <title>Page introuvable | Dotter.science</title>
        <img src={logo} alt="Dotter.science logo" />
        <h1 className={headingStyles}>Page introuvable</h1>
        <p className={paragraphStyles}>
          Le lien actuel n'existe pas ou n'est plus valide.
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Vous pouvez retourner sur la page d'accueil en cliquant sur le
              bouton ci-dessous :
              <br />
            </>
          ) : null}
          <br />
        </p>
        <div className={AccessButtonStyle}>
          <Link to="/" className="navDotter">
            <span className="glassyeffect"></span>
            <span className="text">Retourner sur le site</span>
            <span className="rocket">🚀</span>
          </Link>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default NotFoundPage;
